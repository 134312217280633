<template>
  <div class="grid grid-cols-5">
    <div class="col-span-1 pl-4 pt-4">
      <h2>My Schedule</h2>
      <TFullCalendar
        :events-fn="getDayScheduledCallEvents"
        :aspect-ratio="0.1"
        :event-click="scheduledCallEventClick"
      />
    </div>
    <div class="col-span-4">
      <MyEnrollmentQueueTable
        ref="myEnrollmentQueue"
        @subtask-status-changed="refreshUnassignedTable"
      />
      <UnassignedEnrollmentQueueTable
        ref="unassignedEnrollmentQueue"
        @assign="refreshAssignedTable"
        @subtask-status-changed="refreshAssignedTable"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  EventClickArg,
  EventInput,
  EventSourceFuncArg,
} from '@fullcalendar/core'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import MyEnrollmentQueueTable from '@/legacy/components/queue/enrollmentQueue/MyEnrollmentQueueTable/MyEnrollmentQueueTable.vue'
import UnassignedEnrollmentQueueTable from '@/legacy/components/queue/enrollmentQueue/UnassignedEnrollmentQueueTable/UnassignedEnrollmentQueueTable.vue'
import TFullCalendar from '@/legacy/nashville/fullCalendar/TFullCalendar.vue'
import router from '@/legacy/router'
import { useScheduleApi } from '@/legacy/store/modules/page/schedule'
import { useProfileStore } from '@/legacy/store/modules/profile'

export default defineComponent({
  components: {
    MyEnrollmentQueueTable,
    UnassignedEnrollmentQueueTable,
    TFullCalendar,
  },
  setup() {
    const myEnrollmentQueue = ref<{ getData: () => object } | null>(null)
    const unassignedEnrollmentQueue = ref<{ getData: () => object } | null>(
      null
    )

    /**
     * If one table component needs to refresh the other table,
     * it can use one of these helper methods to do so.
     * These are used as callback functions when the component emits
     * a particular event.
     *
     * For example, if a user assigns themselves a subtask from the Unassigned table,
     * we should refresh the Assigned table so the subtask shows up there.
     * Otherwise the user will need to manually refresh the table to show the subtask
     * in the correct table.
     */
    const refreshAssignedTable = () => myEnrollmentQueue.value?.getData()
    const refreshUnassignedTable = () =>
      unassignedEnrollmentQueue.value?.getData()
    const { data: scheduleData } = storeToRefs(useScheduleApi())

    const { selfEntity } = storeToRefs(useProfileStore())

    /**
     * Fetch all scheduled calls for a staff member on a particular day
     *
     * IMPROVEME show only those scheduled calls for subtasks in the table
     * @param calendarArgs arguments to pass to the calendar component
     */
    async function getDayScheduledCallEvents(calendarArgs: EventSourceFuncArg) {
      await useScheduleApi().listAll({
        params: {
          filter_staff_ids: [selfEntity.value?.entityId],
          filter_due_before: calendarArgs.endStr,
          filter_due_after: calendarArgs.startStr,
        },
      })
      return scheduleData.value as EventInput[]
    }

    /**
     * Redirect to the scheduled call communication on the patient profile
     * when the user clicks on the scheduled call event in the calendar
     * @param eventDetails details of the scheduled call event
     */
    function scheduledCallEventClick(eventDetails: EventClickArg) {
      const commId = eventDetails.event.id
      const patientId = eventDetails.event.extendedProps.patientId
      const routeData = router.resolve({
        name: '#patientProfile',
        params: { patientId },
        query: { commId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      myEnrollmentQueue,
      refreshAssignedTable,
      refreshUnassignedTable,
      getDayScheduledCallEvents,
      scheduledCallEventClick,
    }
  },
})
</script>
