<template>
  <div class="overflow-visible px-5 card-style w-full mb-5">
    <h3 class="font-medium py-2 print-h3">Clinical Summary</h3>
    <TTab
      :tabs="clinicalSummaryTabOptions"
      :active="selectedTab"
      @select="selectTab"
    />
    <component :is="selectedTab" />
  </div>
</template>

<script lang="ts">
import TTab from '@nashville/tab/TTab.vue'
import { defineComponent } from 'vue'
import FallRisk from '@/pages/PatientProfile/CarePlans/ClinicalSummary/FallRisk/FallRisk.vue'
import MedicalConditions from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/MedicalConditions.vue'
import MedicalTeam from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalTeam/MedicalTeam.vue'
import PatientCancerDetails from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientCancerDetails/PatientCancerDetails.vue'
import PatientClinicalSummary from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientClinicalSummary/PatientClinicalSummary.vue'
import PatientTreatment from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientTreatment/PatientTreatment.vue'
import PhysicalFunctionAssessment from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PhysicalFunctionAssessment/PhysicalFunctionAssessment.vue'
import TransitionsOfCare from '@/pages/PatientProfile/CarePlans/ClinicalSummary/TransitionsOfCare/TransitionsOfCare.vue'

import { setup } from './controller'
export default defineComponent({
  components: {
    TTab,
    FallRisk,
    PhysicalFunctionAssessment,
    PatientClinicalSummary,
    PatientCancerDetails,
    PatientTreatment,
    TransitionsOfCare,
    MedicalTeam,
    MedicalConditions,
  },
  setup,
})
</script>
<style lang="scss" scoped></style>
