<template>
  <div class="h-full">
    <h5
      v-if="title"
      data-cy="thymeline-header"
      class="pb-1 mt-5 flex items-end justify-between"
    >
      {{ title }}
    </h5>
    <TTable
      ref="table"
      state-storage="session"
      :filters="filters"
      :name="name"
      :rows="rows"
      :columns="columns"
      :params="params"
      :api-store="apiStore"
      :show-rows-per-page="showRowsPerPage"
      :show-header-container="showHeaderContainer"
      :free-text-filter="freeTextFilter"
      v-bind="$attrs"
    >
      <template #column-thymeline="slotProps">
        <slot :item="slotProps.row" :get-data="getData"></slot>
      </template>
    </TTable>
  </div>
</template>

<script lang="ts">
import { ColumnOptions, TablePadding } from '@thyme/nashville/src/types/tables'
import { PropType, defineComponent, ref } from 'vue'
import { thymeListen } from '@/legacy/libs/eventBus'
import TTable from '@/legacy/nashville/table/TTable.vue'
import { ThymelineItem } from '@/legacy/types/patients/thymelines'

export default defineComponent({
  components: {
    TTable,
  },
  props: {
    showHeaderContainer: {
      type: Boolean,
      default: true,
    },
    showRowsPerPage: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    columns: {
      type: Array as PropType<ColumnOptions[]>,
      required: true,
    },
    apiStore: {
      type: Function,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
    allowColToggle: {
      type: Boolean,
      default: false,
    },
    tablePadding: {
      type: String,
      default: TablePadding.MD,
      validator: (v: string) => Object.values<string>(TablePadding).includes(v),
      // @ts-ignore options custom attr
      options: TablePadding,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    freeTextFilter: {
      type: Boolean,
      default: false,
    },
    freeTextPlaceholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const table = ref<{
      getData: () => object
    } | null>(null)
    const getData = () => table.value?.getData()

    /**
     *
     * @param event
     */
    function patchData(
      event: CustomEvent<{ id: string; item: ThymelineItem['item'] }>
    ) {
      // find item inside thymeline store based on id
      const oldItem = props.apiStore().idMap[event.detail.id]
      const newItem = { ...oldItem, item: event.detail.item }

      // update in place an object which is the old object with item replaced by event.detail
      void props
        .apiStore()
        .updateInPlace({ id: event.detail.id, item: newItem })
    }

    // Allow event bussing to update all active thymelines
    thymeListen('thymeline-update', getData)
    thymeListen('thymeline-patch', patchData)

    return {
      table,
      getData,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.p-datatable) {
  .p-datatable-wrapper {
    overflow: visible;
  }
}
</style>
