<template>
  <div class="overflow-visible px-5 card-style w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row space-x-4 items-center">
            <h3 class="font-medium py-2 print-h3">Social Summary</h3>
          </div>
          <div class="flex flex-row">
            <div v-tooltip.left="'Edit social summary'">
              <TMSecondaryButton
                icon="pencil"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleSocialSummaryEdit"
              />
            </div>
          </div>
        </div>
        <div class="grid-container">
          <label class="t-grid-item">Transportation Barrier</label>
          <div class="t-grid-item">
            {{ transportationBarrierDisplay }}
          </div>
          <label class="t-grid-item">Food Barrier</label>
          <div class="t-grid-item">
            {{ foodBarrierDisplay }}
          </div>
          <label class="t-grid-item">Housing Barrier</label>
          <div class="t-grid-item">
            {{ housingBarrierDisplay }}
          </div>
          <label class="t-grid-item">Financial Barrier</label>
          <div class="t-grid-item">
            {{ financialBarrierDisplay }}
          </div>
          <label class="t-grid-item">Social Support</label>
          <div class="t-grid-item">
            {{ socialSupportDisplay }}
          </div>
          <label class="t-grid-item">In-Home Support</label>
          <div class="t-grid-item">
            {{ inHomeSupportDisplay }}
          </div>
          <label class="t-grid-item">Caregiver Mapping</label>
          <div class="t-grid-item">
            {{ caregiverMappingDisplay }}
          </div>
          <label class="t-grid-item">Benefits, Programs, and Services</label>
          <div class="t-grid-item">
            {{ benefitsProgramsServicesDisplay }}
          </div>
          <label class="t-grid-item">Healthcare Proxy / Healthcare POA</label>
          <div class="t-grid-item">
            {{ isHealthcareProxyOrPOA }}
          </div>
          <label class="t-grid-item">Additional Context</label>
          <TMarkdown
            v-if="additionalContextDisplay"
            class="t-grid-item whitespace-pre-wrap"
            :source="additionalContextDisplay"
          />
          <div v-else class="t-grid-item whitespace-pre-wrap">-</div>
          <label class="t-grid-item">Last update</label>
          <div class="t-grid-item">
            {{ lastUpdatedDisplay }}
          </div>
        </div>
      </div>
    </div>
    <div class="print:hidden my-5">
      <TMTertiaryButton
        v-if="!hasOffboardingSummary"
        label="+ Add Offboarding Summary"
        @click="toggleOffboardingSummaryEdit"
      />
      <OffboardingSummary
        v-else
        :patient-social-summary="patientSocialSummary"
        @toggle-offboarding-summary-edit="toggleOffboardingSummaryEdit"
      />
    </div>
  </div>
  <EditSocialSummary
    v-if="showEditModal"
    :is-open="showEditModal"
    :patient-social-summary="patientSocialSummary"
    :patient-id="patientId"
    @close="toggleSocialSummaryEdit"
    @refetch-social-summary="fetchSocialSummary"
  />
  <OffboardingSummaryEdit
    v-if="showOffBoardingSummaryEdit"
    :is-open="showOffBoardingSummaryEdit"
    :patient-social-summary="patientSocialSummary"
    :patient-id="patientId"
    @close="toggleOffboardingSummaryEdit"
    @refetch-social-summary="fetchSocialSummary"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import OffboardingSummaryEdit from '@/legacy/components/patient/carePlans/modals/EditOffboardingSummary/EditOffboardingSummary.vue'
import EditSocialSummary from '@/legacy/components/patient/carePlans/modals/EditSocialSummary/EditSocialSummary.vue'
import OffboardingSummary from '@/legacy/components/patient/carePlans/PatientCarePlan/OffboardingSummary/OffboardingSummary.vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TMarkdown,
    TMTertiaryButton,
    TMSecondaryButton,
    EditSocialSummary,
    OffboardingSummary,
    OffboardingSummaryEdit,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
