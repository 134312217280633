// NOTE: Add all new colors to __color_definitions__
// so storybook loads them
export const nashColors = {
  // purples
  purple50: '#FAF7F9',
  purple100: '#F5F0F3',
  purple200: '#E6D7E0',
  purple300: '#C09BB1',
  purple400: '#9A6184',
  purple500: '#803663',
  purple550: '#673955',
  purple600: '#471132',
  // wheat
  wheat500: '#FBF8F1',
  wheat600: '#F2E7D1',
  //tigerlily
  tigerlily100: '#FFF2F0',
  tigerlily600: '#FFA398',
  tigerlily700: '#EC6555',
  tigerlily800: '#BD5144',
  //teal
  teal600: '#7ACCC5',
  teal700: '#116978',
  // midnight blue
  midnightBlue100: '#EEF4FD',
  midnightBlue600: '#98BAF4',
  midnightBlue700: '#313F71',
  // neutrals
  neutral000: '#FFFFFF',
  neutral100: '#F9F9FB',
  neutral200: '#F2F1F5',
  neutral300: '#E3E2E7',
  neutral400: '#D7D5DD',
  neutral500: '#C2BECA',
  neutral600: '#98959E',
  neutral700: '#75717E',
  neutral800: '#534E5E',
  neutral900: '#1F1D24',
  // brick (red)
  brick600: '#BF3921',
  brick700: '#8D2310',
  // fern (green)
  fern100: '#F3F6F1',
  fern600: '#50823B',
  fern700: '#385B29',
  // mustard
  mustard100: '#FDF2DA',
  mustard600: '#F4BC44',
  mustard700: '#59491D',
}

export default {
  // any files in the project that may reference tailwind styles by name
  content: ['./src/**/*.{vue,js,ts,jsx,tsx}', './index.html'],
  darkMode: 'media', // or 'media' or 'class'
  safelist: [
    {
      pattern: /.-nash-./,
    },
  ],
  theme: {
    extend: {
      minHeight: {
        300: '300px',
        450: '450px',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        textMessagesMinHeight: '200px',
      },
      maxHeight: {
        856: '856px',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        '95pct': '95%',
      },
      width: {
        700: '43.75rem',
        '3xl': '48rem',
      },
      minWidth: {
        155: '155px',
      },
      maxWidth: {
        225: '225px',
      },
      height: {
        textMessagesHeight: 'calc(100vh - 800px)',
      },
      transitionProperty: {
        border: 'border, border-radius',
      },
      colors: { nash: nashColors },
      borderWidth: {
        1: '1px',
      },
      screens: {
        print: { raw: 'print' },
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
}
