<template>
  <TModal
    :is-visible="isVisible"
    title="Edit Consents"
    :size="ModalSize.SM"
    :flex="true"
    :allow-overflow="true"
    @close="close"
  >
    <div class="flex flex-row-reverse pb-1 mt-0">
      <div v-tooltip.left="toolTipText()">
        <TMSecondaryButton
          :disabled="!exportEnabled()"
          icon="heroicons:arrow-up-tray"
          label="Export"
          name="export-consent"
          @click="exportForm"
        />
      </div>
    </div>
    <div class="flex flex-col gap-y-5">
      <div
        v-for="(consent, cType) in displayConsents"
        :key="cType"
        :class="`consents-wrapper ${
          consent && editingId === consent.patientConsentId ? 'editing' : ''
        }`"
      >
        <div
          v-if="
            editingId !== consent?.patientConsentId && creatingType !== cType
          "
          class="flex justify-between items-center"
        >
          <div>
            <div class="flex flex-col">
              <h5>{{ formatConsentTitle(cType) }}</h5>
            </div>
          </div>
          <div class="flex">
            <LegacyTDropdown
              icon="meatball"
              name="consent_context_menu"
              model-value=""
              size="sm"
              position="bottom-left"
              :expanded="false"
              :unmask="true"
              :show-elements-selected="true"
              :options="consentActions(consent)"
              @update:model-value="
                triggerOptions($event, consent ?? undefined, cType)
              "
            />
          </div>
        </div>

        <div
          v-if="consent && editingId && editingId === consent.patientConsentId"
        >
          <ConsentForm :consent="consent" :con-type="cType" @submit="submit" />
        </div>

        <div v-if="creatingType === cType">
          <ConsentForm
            :creating="true"
            :con-type="creatingType"
            @submit="submit"
          />
        </div>
      </div>
    </div>
    <template #actions>
      <div class="mt-3">
        <TMPrimaryButton
          class="ml-4"
          name="close-modal"
          label="Close"
          @click="close"
        />
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import FormsortWebEmbed, { IFormsortWebEmbed } from '@formsort/web-embed-api'
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { DropdownTriggerOptions } from '@thyme/nashville/src/types/dropdowns'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { pick } from 'lodash'

import { storeToRefs } from 'pinia'
import { defineComponent, ref, PropType, computed } from 'vue'
import { lookupEnum } from '@/legacy/libs/enum'
import { formatConsentTitle } from '@/legacy/libs/format'
import { getQueryParams } from '@/legacy/libs/formsort'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useConsentsApi } from '@/legacy/store/modules/consents'
import { useFormResponsesApi } from '@/legacy/store/modules/formResponses'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { UUID } from '@/legacy/types/global/base'
import { NotificationType } from '@/legacy/types/notifications'
import {
  Consent,
  ConsentType,
  AddConsentForm,
  updateConsentFields,
  ConsentSource,
} from '@/legacy/types/patients/consents'
import { NormalizedPatient } from '@/legacy/types/patients/patients'
import ConsentForm from '../forms/ConsentForm.vue'

export default defineComponent({
  components: {
    TMSecondaryButton,
    TMPrimaryButton,
    TModal,
    LegacyTDropdown,
    ConsentForm,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    displayConsents: {
      type: Object as PropType<Record<ConsentType, Consent | null>>,
      default: null,
    },
    patient: {
      type: Object as PropType<NormalizedPatient>,
      required: true,
    },
  },
  emits: ['close', 'triggerRefresh'],
  setup(props, context) {
    const { data: formResponses } = storeToRefs(useFormResponsesApi())
    const { configVals } = storeToRefs(useConfigStore())
    const editingId = ref<string | null>(null)
    const formEmbed = ref<IFormsortWebEmbed | null>(null)
    const creatingType = ref<ConsentType | null>(null)

    const formResponseIds = computed(() => {
      const allIds: Array<UUID> = []
      for (const consent of Object.values(props.displayConsents)) {
        if (
          consent &&
          lookupEnum(ConsentSource, consent?.source) ===
            ConsentSource.formsort &&
          consent.formsortConsent
        ) {
          allIds.push(consent.formsortConsent.formResponseId)
        }
      }

      const uniqIds: Array<UUID> = allIds.filter(
        (val, idx, arr) => arr.indexOf(val) === idx
      )
      return uniqIds
    })

    /**
     * Function to determine appropriate context menu options
     * for a given consent/consentType
     * @param consent
     */
    function consentActions(consent: Consent | null) {
      if (consent) {
        return [[DropdownTriggerOptions.edit, 'Update']]
      } else {
        return [[DropdownTriggerOptions.add, 'Add']]
      }
    }

    /**
     * Function for reseting edit/create UI
     */
    function reset() {
      editingId.value = null
      creatingType.value = null
    }

    /**
     *
     */
    function close() {
      reset()
      context.emit('close')
    }

    /**
     * Function to submit consent form for creating or updating
     * @param consentChange
     */
    async function submit(consentChange: AddConsentForm) {
      let consent: Consent
      if (editingId.value) {
        consent = pick(consentChange, updateConsentFields) as Consent
      } else {
        consent = {
          ...pick(consentChange, updateConsentFields),
          patientId: props.patient.entityId,
          consentType: creatingType.value,
        } as Consent
      }

      try {
        if (editingId.value) {
          await useConsentsApi().partialUpdate({
            body: consent,
            ids: [editingId.value],
          })
        } else {
          await useConsentsApi().create({ body: consent })
        }
        useNotificationStore().setNotification({
          message: 'Successfully saved consent.',
          type: NotificationType.SUCCESS,
        })
      } catch (err) {
        useNotificationStore().setNotification({
          message: 'Failed to save consent.',
          type: NotificationType.DANGER,
        })
        return
      } finally {
        context.emit('triggerRefresh')
        reset()
      }
    }

    /**
     * Button action, when triggered fetches the form response
     * data associated to the consent (if there is any) and
     * then opens a new window with that form rendered (using
     * FormsortWebEmbed) for printing
     */
    async function exportForm() {
      const responseIds = formResponseIds.value

      if (responseIds.length) {
        try {
          await useFormResponsesApi().list({
            params: {
              filter_form_response_ids: responseIds,
            },
          })
        } catch (err) {
          useNotificationStore().setNotification({
            message: 'Unable to retrieve from data.',
            type: NotificationType.DANGER,
          })
        }

        if (formResponses.value) {
          for (const formResponse of formResponses.value) {
            // create a div for FormsortWebEmbed to attach
            // the iframe to
            const printDiv = document.createElement('div')
            // disable pointer events to prevent editing/submitting the form
            printDiv.setAttribute('style', 'pointer-events: none')
            // embed the form
            formEmbed.value = FormsortWebEmbed(printDiv, {
              style: {
                width: '100%',
                // setting the height statically like this is brittle, but because
                // FormsortWebEmbed uses an iframe and given some of the CORS restrictions
                // for iframes with a src tag under a different domain than app, we must set
                // this value to be long enough that the entire formsort form will be visible
                // when printed
                height: '5800px',
              },
            })

            // open a new window to render the form in for printing
            const tempWindow = window.open('', 'Print', 'popup')

            if (!tempWindow) {
              console.error(
                'Consent Export Popup unable to open. This could be caused by an ad-blocker'
              )
              return
            }

            tempWindow.document.title = 'Consent Export'

            // set margin and height to 0 otherwise there is a full page's worth
            // of whitespace that precedes the form when printing
            tempWindow.document.body.setAttribute('topmargin', '0')
            tempWindow.document.body.setAttribute('marginheight', '0')

            // append the div that now houses the formsort iframe in the body of the new window
            tempWindow.document.body.appendChild(printDiv)

            if (!configVals.value?.env) {
              console.error('Cannot export consent, no environment loaded')
              return
            }

            // render the form
            formEmbed.value.loadFlow(
              'GVGy7e-KFn',
              formResponse.flowLabel,
              formResponse.variantLabel,
              getQueryParams(
                formResponse.responderUuid,
                configVals.value.env,
                null,
                formResponse.prefillData
              )
            )
          }
        } else {
          console.warn('Unable to locate form response data')
        }
      }
    }

    /**
     * Function to handle consent drop down selection
     * @param value
     * @param consent
     * @param cType
     */
    async function triggerOptions(
      value: DropdownTriggerOptions,
      consent: Consent | undefined,
      cType: ConsentType
    ) {
      const conset_id = consent?.patientConsentId ?? null
      if (value === DropdownTriggerOptions.edit) {
        editingId.value = conset_id
        creatingType.value = null
      } else if (value === DropdownTriggerOptions.add) {
        editingId.value = null
        creatingType.value = cType
      }
    }

    /**
     *
     */
    function exportEnabled() {
      if (formResponseIds.value.length) {
        return true
      } else {
        return false
      }
    }

    /**
     *
     */
    function toolTipText() {
      if (!exportEnabled()) {
        return 'No consent forms available to export'
      }
    }

    return {
      formatConsentTitle,
      consentActions,
      triggerOptions,
      editingId,
      close,
      submit,
      creatingType,
      exportForm,
      exportEnabled,
      toolTipText,
      ModalSize,
    }
  },
})
</script>
<style lang="scss">
.consents-wrapper {
  @apply border border-nash-neutral400 rounded-lg py-4 px-4;
  &.editing {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
