<template>
  <Breadcrumb :home="home" :model="items" v-bind="$attrs">
    <template #item="{ item }">
      <router-link
        v-if="item.to"
        v-slot="{ href, navigate }"
        :to="item.to ?? ''"
      >
        <a
          data-cy="breadcrumb-item"
          :href="href"
          class="hover:underline"
          @click="navigate"
          >{{ item.label }}</a
        >
      </router-link>
      <span v-else data-cy="breadcrumb-item" class="text-nash-purple600">{{
        item.label
      }}</span>
    </template>
  </Breadcrumb>
</template>

<script lang="ts">
import Breadcrumb from 'primevue/breadcrumb'
import { defineComponent, PropType } from 'vue'
import type { MenuItem } from 'primevue/menuitem'

export default defineComponent({
  components: {
    Breadcrumb,
  },
  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
      default() {
        return []
      },
    },
    home: {
      type: Object as PropType<MenuItem>,
      default: undefined,
    },
  },
  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
:deep .p-breadcrumb-list {
  @apply space-x-2;

  .p-menuitem-separator {
    @apply text-xs;
  }
}
</style>
