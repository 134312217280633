import { createDisposition } from '@/legacy/components/patient/communicationV2/lib/callDispositions'
import { createCommunication } from '@/legacy/components/patient/communicationV2/lib/communications'
import { createPlannedCall } from '@/legacy/components/patient/communicationV2/lib/plannedCalls'
import router from '@/legacy/router'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  CommunicationDisplayType,
  CommunicationSubtype,
  CommunicationType,
  communicationTypeList,
  SCHEDULED_CALL_TYPE,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'

/**
 *
 * Setup function for CommunicationsMenu.vue
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  /**
   *
   * Function to trigger selecting a communication type
   * from the communication menu.
   *
   * If "Scheduled Call" is selected, the UI will trigger the
   * scheduled calls modal to create communication and subtype
   * from that workflow.
   *
   * Otherwise:
   * 1. Create the communication and it's applicable subtype
   *    based on selected communication type
   * 2. Set the communication type in store to trigger correct
   *    sidebar UI
   * @param commType
   */
  async function selectCommType(commType: CommunicationDisplayType) {
    if (commType.label === SCHEDULED_CALL_TYPE.label) {
      context.emit('scheduledCallModalOpen')
      return
    }

    const createdComm = await createCommunication(commType)

    if (!createdComm) {
      useNotificationStore().setNotification({
        message: `Communication for ${commType.type} not created`,
        type: NotificationType.DANGER,
      })
      return
    }

    /**
     * IMPROVEME(MT-2925)
     * is it possible to handle these within the disposition/planned call components themselves?
     * e.g. emit event with commId and payload to subtype component which handles the subtype create/update
     */
    let createdCommSubtype = null
    const commId = createdComm.communicationId
    if (createdComm.type === CommunicationType.Call) {
      if (commType.subtype === CommunicationSubtype.Disposition) {
        createdCommSubtype = await createDisposition(commId, commType.payload)
      } else if (commType.subtype === CommunicationSubtype.Planned) {
        createdCommSubtype = await createPlannedCall(commId, commType.payload)
      }

      if (!createdCommSubtype) {
        useNotificationStore().setNotification({
          message: `Communication Subtype ${commType.subtype} not created`,
          type: NotificationType.DANGER,
        })
        return
      }
    }

    useCommunicationsStore().communicationType = commType

    await router.push({
      query: {
        commId: createdComm.communicationId,
      },
    })

    useNotificationStore().setNotification({
      message: `Communication for ${commType.label} created`,
      type: NotificationType.SUCCESS,
    })
  }

  return {
    communicationTypeList,
    selectCommType,
  }
}
