<template>
  <StaffQueueTable ref="staffQueue" />
  <TeamQueueTable
    :has-bulk-edit="true"
    :is-pod-lead-queue="true"
    @assign="refreshMembers"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import StaffQueueTable from '@/legacy/components/queue/StaffQueueTable/StaffQueueTable.vue'
import TeamQueueTable from '@/legacy/components/queue/TeamQueueTable/TeamQueueTable.vue'

export default defineComponent({
  components: { StaffQueueTable, TeamQueueTable },
  setup() {
    const staffQueue = ref<{ getData: () => object } | null>(null)

    // We need to pass this to the unassigned table because when users assign
    // themselves a certain subtask from unassigned queue, we also need to
    // refresh the my members table along with unassigned table.
    const refreshMembers = () => staffQueue.value?.getData()

    return {
      staffQueue,
      refreshMembers,
    }
  },
})
</script>
