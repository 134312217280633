import { PropType } from 'vue'
import { object, string, InferType, boolean, array } from 'yup'
import { ManageMedicalConditionDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/ManageMedicalConditions/shared/types'

export const schema = object({
  isArchived: boolean(),
  conditionSpecificAttributes: array().of(string().required()).nullable(),
})

export type EditMedicalConditionsForm = InferType<typeof schema>

export const editMedicalConditionsProps = {
  medicalConditionsDisplay: {
    type: Object as PropType<ManageMedicalConditionDisplay[]>,
    required: true,
  },
  updateAttrsMedicalCondition: {
    type: Object as PropType<ManageMedicalConditionDisplay | null>,
    default: null,
  },
  confirmArchiveMedicalCondition: {
    type: Object as PropType<ManageMedicalConditionDisplay | null>,
    default: null,
  },
} as const
