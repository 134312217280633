<template>
  <div>
    <h3
      data-cy="activity-details-header"
      class="flex justify-between items-center content-center mb-5"
    >
      <div>
        <b>Subtasks</b>
        <span v-if="!hideSubtaskEstimatedCompletionTime"
          >(Est:
          {{ hasNoLinkedComms ? '0 min' : `${totalEstimatedTime} min` }})</span
        >
      </div>
      <div
        class="flow-root text-sm font-normal items-center content-center space-y-2"
      >
        <div v-if="!openSubtaskMenu" class="flex items-center float-right">
          <LegacyTButton
            v-if="!isEditing"
            type="whiteAndPurpleTertiary"
            icon="pencil"
            @click="isEditing = true"
          />
          <TMTertiaryButton v-else label="Done" @click="isEditing = false" />
          <slot name="additionalActions"></slot>
        </div>
      </div>
    </h3>

    <div v-if="hasNoLinkedComms" class="text-nash-neutral700 mb-5">
      No subtasks associated with this communication.
    </div>

    <div v-if="!openSubtaskMenu" class="relative">
      <TSpinner
        v-if="isLoadingPathways || patientLoading"
        name="subtask-sidebar-data"
        :partial-page="true"
      />

      <div v-if="!!linkedSubtasks.length">
        <div
          v-for="linkedSubtask in linkedSubtasks"
          :key="linkedSubtask?.subtaskId"
          class="mb-5"
        >
          <div v-if="linkedSubtask">
            <div v-if="isEditing">
              <div class="flex justify-between items-center content-center">
                <h4>{{ linkedSubtask.title }}</h4>
                <div class="h-5 w-5">
                  <TIcon
                    icon="close"
                    class="cursor-pointer text-nash-neutral700"
                    @click="
                      delinkSubtask({ removingId: linkedSubtask.subtaskId })
                    "
                  />
                </div>
              </div>
            </div>
            <TCollapse v-if="!isEditing" :use-v-show="true">
              <template #trigger>
                <div class="flex justify-between items-center content-center">
                  <h4>{{ linkedSubtask.title }}</h4>
                </div>
              </template>
              <template #content>
                <SubtaskDetail
                  :key="linkedSubtask?.subtaskId"
                  :subtask="linkedSubtask"
                  :is-subtask-sidebar="false"
                  @callback-fn="refetchPostUpdate"
                />
              </template>
            </TCollapse>
          </div>
        </div>
      </div>
    </div>
    <div class="flow-root my-5 pb-5">
      <TMPrimaryButton
        name="add-subtask"
        label="+ Add Subtask"
        @click="showAddSubtaskModal = true"
      />
      <AddActualSubtaskModal
        :linked-subtask-ids="subtaskIds"
        :is-visible="showAddSubtaskModal"
        @close="showAddSubtaskModal = false"
        @link-subtasks="linkSubtasks"
        @open-create-new-evergreen-tasks-modal="
          openCreateNewEvergreenTasksModalAndCloseAddSubtaskModal
        "
      />
      <AddTaskModal
        type="tasks"
        :title="`Create New Evergreen Tasks`"
        :is-visible="showCreateNewEvergreenTasksModal"
        :pathway-id="evergreenPathway?.pathwayId"
        :communication-id-to-link="queryCommId"
        @close="showCreateNewEvergreenTasksModal = false"
        @save-add-with-comm-link="createTasksAndLinkCommunication"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { datadogRum } from '@datadog/browser-rum'
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType, ref, watch } from 'vue'

import { useRoute } from 'vue-router'
import AddActualSubtaskModal from '@/legacy/components/patient/pathways/AddActualSubtaskModal.vue'
import AddTaskModal from '@/legacy/components/patient/pathways/AddTaskModal.vue'
import SubtaskDetail from '@/legacy/components/patient/pathways/SubtaskDetail.vue'

import { thymeDispatch } from '@/legacy/libs/eventBus'
import { toIdMap } from '@/legacy/libs/store'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TCollapse from '@/legacy/nashville/TCollapse.vue'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePathwayApi } from '@/legacy/store/modules/pathways'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  useSubtasksStore,
  useSubtaskTemplatesByIdApi,
} from '@/legacy/store/modules/subtasks'
import {
  useCreateTasksAndLinkCommunicationApi,
  useTaskApi,
} from '@/legacy/store/modules/tasks'
import { CommunicationSubtype } from '@/legacy/types/communications/communications'
import { Notification, NotificationType } from '@/legacy/types/notifications'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import {
  CreateTasksAndLinkCommunicationResponse,
  CreateTaskInfo,
} from '@/legacy/types/pathways/tasks'

export default defineComponent({
  components: {
    TSpinner,
    TMTertiaryButton,
    TMPrimaryButton,
    SubtaskDetail,
    AddActualSubtaskModal,
    LegacyTButton,
    TCollapse,
    TIcon,
    AddTaskModal,
  },
  props: {
    openSubtaskMenu: { type: Boolean, required: true },
    subtaskIds: { type: Array as PropType<string[]>, required: true },
  },
  emits: [
    'addSideEffect',
    'addOpenFormsortForms',
    'removeSideEffect',
    'createTasksAndLinkCommunication',
    'refreshLinkedSubtasks',
  ],
  setup(props, context) {
    const route = useRoute()
    const linkedSubtasks = ref<Subtask[]>([])

    const queryCommId = computed(() => `${route.query.commId ?? ''}`)
    const patientId = computed(() => `${route.params.patientId ?? ''}`)

    const { patient, isLoading: patientLoading } = storeToRefs(
      usePatientStore()
    )
    const { data: tasks } = storeToRefs(useTaskApi())
    const { data: subtaskTemplatesById } = storeToRefs(
      useSubtaskTemplatesByIdApi()
    )

    const createTasksAndLinkCommunicationApi =
      useCreateTasksAndLinkCommunicationApi()

    const { communicationType } = storeToRefs(useCommunicationsStore())

    const isEditing = ref(false)

    const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())

    const showAddSubtaskModal = ref(false)
    const pathwayApi = usePathwayApi()
    const { data: pathways } = storeToRefs(pathwayApi)
    const evergreenPathway = computed(() =>
      Object.values(pathways.value ?? {}).find(
        (p) => p.title.toLowerCase() === 'evergreen'
      )
    )

    const isLoadingPathways = computed(() => pathwayApi.isLoading)

    const showCreateNewEvergreenTasksModal = ref(false)

    const currentCommIsCallType = computed(
      () =>
        communicationType.value?.subtype === CommunicationSubtype.Planned ||
        communicationType.value?.subtype === CommunicationSubtype.Disposition
    )

    const hasNoLinkedComms = computed(() => props.subtaskIds.length === 0)
    const linkedSubtasksById = computed(() =>
      toIdMap(linkedSubtasks.value, 'subtaskId')
    )

    const totalEstimatedTime = computed(() => {
      let total = 0

      linkedSubtasks.value.forEach((subtask: any) => {
        total += subtask?.estimatedMinutesToComplete ?? 0
      })

      return total
    })

    /**
     * Function called when linking a subtask to a communication
     * @param newIds
     */
    async function linkSubtasks(newIds: string[]) {
      context.emit('addSideEffect', { newIds: newIds })
    }

    /**
     * Function called to check if subtask has open Formsort form
     * @param subtaskId
     */
    function hasOpenFormsortForms(subtaskId: string) {
      const matchedSubtask =
        linkedSubtasks.value && linkedSubtasksById.value[subtaskId]
      const template =
        subtaskTemplatesById.value && matchedSubtask
          ? subtaskTemplatesById.value[matchedSubtask.subtaskVariantId]
          : null

      return template ? !!template.staffCompletableForms?.length : false
    }
    const emitFormsortForms = (subtaskIds: string[]) => {
      const subtasksWithFormsortIds: string[] = []

      subtaskIds.forEach((subtaskId) => {
        if (hasOpenFormsortForms(subtaskId)) {
          subtasksWithFormsortIds.push(subtaskId)
        }
      })
      context.emit('addOpenFormsortForms', { subtasksWithFormsortIds })
    }

    /**
     * Bring up the Create New Evergreen Tasks modal and hide the Add Subtask modal
     * Called when the user needs to create a task on the communication
     */
    const openCreateNewEvergreenTasksModalAndCloseAddSubtaskModal = () => {
      showAddSubtaskModal.value = false
      showCreateNewEvergreenTasksModal.value = true
    }

    /**
     * Calls an API to create a batch of tasks and link their subtasks to the communication present in the route query params
     * @param tasksToCreate - The tasks to create and link to the communication
     */
    const createTasksAndLinkCommunication = async (
      tasksToCreate: CreateTaskInfo[]
    ) => {
      const response = await createTasksAndLinkCommunicationApi.create({
        body: {
          tasks: tasksToCreate,
          communicationId: queryCommId.value,
        },
      })

      useNotificationStore().setNotification(
        getCreateTasksAndLinkCommToast(response)
      )

      const newlyLinkedSubtaskIds: string[] = response.subtasksLinked.map(
        (subtask: Subtask) => subtask.subtaskId
      )
      const allLinkedSubtaskIds: string[] = props.subtaskIds.concat(
        newlyLinkedSubtaskIds
      )
      context.emit('refreshLinkedSubtasks', allLinkedSubtaskIds)
      thymeDispatch('thymeline-update')

      const taskTitlesCreated = response.tasksCreated.map(
        (task: { title: string }) => task.title
      )

      // For feature usage tracking. Add values to this action as needed.
      datadogRum.addAction('createTasksAndLinkCommunication', {
        tasksCreated: taskTitlesCreated,
      })
    }

    /**
     * Generates a helpful notification  based on the response from a task creation and subtask linking operation.
     * The returned notification can be raised using the NotificationStore.
     * @param {CreateTasksAndLinkCommunicationResponse} response - The response object from the task creation and subtask linking operation.
     *   - tasksCreated: list of tasks that were successfully created.
     *   - subtasksLinked: list of subtasks that were successfully linked.
     *   - tasksNotCreated: list of tasks that could not be created. This property is optional.
     *   - subtasksNotLinked: list of subtasks that could not be linked. This property is optional.
     * @returns {Notification} A toast notification with helpful message
     */
    const getCreateTasksAndLinkCommToast = (
      response: CreateTasksAndLinkCommunicationResponse
    ): Notification => {
      const numTasksCreated = response.tasksCreated.length
      const numSubtasksLinked = response.subtasksLinked.length
      const numTasksNotCreated = response.tasksNotCreated
        ? response.tasksNotCreated.length
        : 0
      const numSubtasksNotLinked = response.subtasksNotLinked
        ? response.subtasksNotLinked.length
        : 0

      const allTasksCreated = numTasksCreated > 0 && numTasksNotCreated === 0
      const allSubtasksLinked =
        numSubtasksLinked > 0 && numSubtasksNotLinked === 0

      const someTasksCreated = numTasksCreated > 0 && numTasksNotCreated > 0
      const someSubtasksLinked =
        numSubtasksLinked > 0 && numSubtasksNotLinked > 0

      const noSubtasksLinked =
        numSubtasksLinked === 0 && numSubtasksNotLinked > 0

      const taskOrTasks = numTasksCreated > 1 ? 'tasks' : 'task'
      const subtaskOrSubtasks = numSubtasksLinked > 1 ? 'subtasks' : 'subtask'

      if (allTasksCreated && allSubtasksLinked) {
        return {
          message: `${numTasksCreated} ${taskOrTasks} created and ${numSubtasksLinked} ${subtaskOrSubtasks} associated with communication`,
          type: NotificationType.SUCCESS,
        }
      } else if (someTasksCreated && allSubtasksLinked) {
        return {
          message: `${numTasksCreated}/${
            numTasksNotCreated + numTasksCreated
          } tasks created and associated with communication`,
          type: NotificationType.DANGER,
        }
      } else if (allTasksCreated && someSubtasksLinked) {
        return {
          message: `${numTasksCreated} ${taskOrTasks} created, ${numSubtasksLinked}/${
            numSubtasksNotLinked + numSubtasksLinked
          } subtasks associated with communication`,
          type: NotificationType.DANGER,
        }
      } else if (someTasksCreated && someSubtasksLinked) {
        return {
          message: `${numTasksCreated}/${
            numTasksNotCreated + numTasksCreated
          } tasks created and ${numSubtasksLinked}/${
            numSubtasksNotLinked + numSubtasksLinked
          } subtasks associated with communication`,
          type: NotificationType.DANGER,
        }
      } else if (allTasksCreated && noSubtasksLinked) {
        return {
          message: `${numTasksCreated} ${taskOrTasks} created, but subtasks could not be associated with communication`,
          type: NotificationType.DANGER,
        }
      } else {
        return {
          message: 'Tasks could not be created',
          type: NotificationType.DANGER,
        }
      }
    }

    /**
     * Function called when de-linking a subtask from communication
     */
    async function delinkSubtask({ removingId }: { [key: string]: string }) {
      const subtaskHasOpenFormsortForms = hasOpenFormsortForms(removingId)

      if (
        !subtaskHasOpenFormsortForms ||
        window.confirm(
          'There is a form in this subtask. Complete it before closing the subtask, or your changes will be lost!'
        )
      ) {
        context.emit('removeSideEffect', { removingId })
      }
    }

    /**
     * Refetch linked subtask by ids and reset subtasks ref value
     */
    async function fetchLinkedSubtasks() {
      if (props.subtaskIds.length) {
        const results = await useSubtasksStore().getSubtasks({
          filter_patient_ids: [patientId.value],
          filter_subtask_ids: props.subtaskIds,
          page_length: props.subtaskIds.length,
        })
        linkedSubtasks.value = results.data
      } else {
        linkedSubtasks.value = []
      }
    }

    /**
     * Refetch linked subtask by ids and thymelines
     */
    async function refetchPostUpdate() {
      await fetchLinkedSubtasks()
    }

    // Ensure updates from thymeline register updates in this component
    watch(tasks, async () => await fetchLinkedSubtasks())

    watch(
      () => props.subtaskIds,
      async () => {
        if (props.subtaskIds.length) {
          await fetchLinkedSubtasks()
        }
        const matchedSubtaskVariantIds: string[] = []
        linkedSubtasks.value.forEach(
          (subtask) =>
            subtask && matchedSubtaskVariantIds.push(subtask.subtaskVariantId)
        )

        const unmatchedSubtaskTemplate = matchedSubtaskVariantIds.find(
          (variantId) =>
            !subtaskTemplatesById.value ||
            !subtaskTemplatesById.value[variantId]
        )
        if (unmatchedSubtaskTemplate) {
          await useSubtasksStore().getSubtaskTemplatesById({
            filter_variant_ids: matchedSubtaskVariantIds,
          })
        }

        emitFormsortForms(props.subtaskIds)
      }
    )

    return {
      refetchPostUpdate,
      queryCommId,
      totalEstimatedTime,
      hasNoLinkedComms,
      currentCommIsCallType,
      isEditing,
      hideSubtaskEstimatedCompletionTime,
      linkedSubtasks,
      linkSubtasks,
      delinkSubtask,
      patientLoading,
      patient,
      showAddSubtaskModal,
      isLoadingPathways,
      openCreateNewEvergreenTasksModalAndCloseAddSubtaskModal,
      showCreateNewEvergreenTasksModal,
      createTasksAndLinkCommunication,
      evergreenPathway,
    }
  },
})
</script>
