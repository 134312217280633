<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <label class="t-grid-item">Last Oncology Appointment</label>
          <div class="t-grid-item">
            {{ lastOncologyAppt }}
          </div>
          <label class="t-grid-item">Next Oncology Appointment</label>
          <div class="t-grid-item">
            {{ nextOncologyAppt }}
          </div>
          <label class="t-grid-item">Oncologist Name</label>
          <div class="t-grid-item">
            {{ oncologistName }}
          </div>
          <label class="t-grid-item">Surgeon Name</label>
          <div class="t-grid-item">
            {{ surgeonName }}
          </div>
          <label class="t-grid-item">Radiation Oncologist Name</label>
          <div class="t-grid-item">
            {{ radiationOncologistName }}
          </div>
          <label class="t-grid-item">Palliative Medicine Name</label>
          <div class="t-grid-item">
            {{ palliativeMedicineName }}
          </div>
          <label class="t-grid-item">Primary Care Name</label>
          <div class="t-grid-item">
            {{ primaryCareName }}
          </div>
          <label class="t-grid-item">Additional Information</label>
          <TMarkdown
            v-if="additionalContext"
            class="t-grid-item whitespace-pre-wrap"
            :source="additionalContext"
          />
          <div v-else class="t-grid-item whitespace-pre-wrap">-</div>
          <label class="t-grid-item">Last update</label>
          <div class="t-grid-item">
            {{ lastUpdatedDisplay }}
          </div>
        </div>
      </div>
      <div v-tooltip.left="'Edit Medical Team'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="toggleMedicalTeamEdit"
        />
      </div>
    </div>
  </div>
  <EditMedicalTeam
    v-if="showEditModal"
    :patient-id="patientId"
    :patient-med-team-id="patientMedTeamId"
    :initial-data="editModalInitialData"
    @close="toggleMedicalTeamEdit"
    @refetch="refetchMedicalTeam"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import { setup } from './controller'
import EditMedicalTeam from './EditMedicalTeam/EditMedicalTeam.vue'

export default defineComponent({
  components: {
    TMarkdown,
    EditMedicalTeam,
    TMSecondaryButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
