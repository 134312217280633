<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <label class="t-grid-item">Cancer</label>
          <div class="t-grid-item">
            {{ cancerDisplay }}
          </div>
          <label class="t-grid-item">Spread</label>
          <div class="t-grid-item">
            {{ spreadDisplay }}
          </div>
          <label class="t-grid-item">Current Treatment(s)</label>
          <div class="t-grid-item">
            {{ currentTreatmentsDisplay }}
          </div>
          <label class="t-grid-item">Medical Conditions</label>
          <div class="t-grid-item">
            {{ medicalConditionsDisplay }}
          </div>
          <label class="t-grid-item">ECOG Status</label>
          <div class="t-grid-item">
            {{ ecogStatusDisplay }}
          </div>
          <label class="t-grid-item">TOC</label>
          <div class="t-grid-item">
            {{ tocLastDischargeDateDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
