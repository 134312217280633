import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { CallDirection } from '@/legacy/types/communications/callDispositions'
import {
  Communication,
  CommunicationSubtype,
  CommunicationType,
  OUTBOUND_CALL_TYPE_POST,
  communicationTypeList,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'
import { getDisposition } from './callDispositions'
import { getPlannedCall } from './plannedCalls'

/**
 * Function to get current staff ID from profile store.
 */
export function getCurrentStaffId() {
  const staffId = useProfileStore().selfEntity?.entityId
  if (staffId == null) {
    useNotificationStore().setNotification({
      message:
        'Cannot find staff ID. Please try logging out and back in to Thymebox.',
      type: NotificationType.DANGER,
    })
    return
  }
  return staffId
}

/**
 * Function to get current patient ID from patient store.
 */
export function getCurrentPatientId() {
  const patientId = usePatientStore().patient?.entityId
  if (patientId == null) {
    useNotificationStore().setNotification({
      message:
        'Cannot find patient ID. Please try logging out and back in to Thymebox.',
      type: NotificationType.DANGER,
    })
    return
  }
  return patientId
}

/**
 *
 * Function to get communication display type.
 * Display types: call, text, email, other, fax
 * @param comm
 */
export function getCommunicationDisplayType(comm: Communication | null) {
  if (!comm) {
    return
  }
  return communicationTypeList.find((type) => {
    if (
      (comm.type as unknown as CommunicationType) !== CommunicationType.Call
    ) {
      return type.type === (comm.type as unknown as CommunicationType)
    } else {
      if (comm.completedDatetime && comm.callDisposition) {
        const isInbound =
          comm.callDisposition.direction === CallDirection.inbound
        const typeDirection = type.payload?.direction
        return isInbound
          ? typeDirection === CallDirection.inbound
          : OUTBOUND_CALL_TYPE_POST
      } else {
        return type.subtype === CommunicationSubtype.Planned
      }
    }
  })
}

/**
 *
 * Function to retrieve a communication's subtype(s).
 * Subtypes relative to this function: planned call, call disposition
 * @param comm
 */
export async function retrieveCommunicationSubType(comm: Communication | null) {
  if (!comm) {
    useNotificationStore().setNotification({
      message: 'Cannot fetch communication subtype without communication',
      type: NotificationType.DANGER,
    })
    return
  }

  if ((comm.type as unknown as CommunicationType) !== CommunicationType.Call) {
    return
  }

  if (comm.completedDatetime) {
    const callDisposition = await getDisposition(comm.communicationId)
    if (callDisposition?.direction === CallDirection.outbound) {
      await getPlannedCall(comm.communicationId)
    }
    return
  } else {
    await getPlannedCall(comm.communicationId)
  }
}
