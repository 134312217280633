<template>
  <div class="texting-thymeline-container">
    <TextingInbox class="texting-inbox" />
    <ActiveConversation class="active-conversation" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import ActiveConversation from '@/legacy/components/texting/ActiveConversation/ActiveConversation.vue'
import TextingInbox from '@/legacy/components/texting/TextingInbox/TextingInbox.vue'

export default defineComponent({
  components: { ActiveConversation, TextingInbox },
  setup() {
    return {}
  },
})
</script>
<style lang="scss" scoped>
.texting-thymeline-container {
  @apply relative border-y border-nash-neutral300 flex flex-row h-1/3;
  height: 650px;
}

.texting-inbox {
  @apply h-full p-3 relative w-1/3 border-r border-nash-neutral300 flex flex-col;
}

.active-conversation {
  @apply h-full relative w-2/3 flex flex-col;
}
</style>
