import { createProp } from '@thyme/nashville/src/utils/designTokens'

export enum IconPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum FillColor {
  Green = 'green',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
  Gray = 'gray',
  Purple = 'purple',
  Null = '',
  LightPurple = 'lightPurple',
  Purple500 = 'purple500',
  Neutral600 = 'neutral600',
  Neutral500 = 'neutral500',
  Neutral000 = 'neutral000',
}

export enum IconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export const IconifyIcons = {
  arrowRightCircle: 'heroicons:arrow-right-circle',
  backspace: 'heroicons:backspace',
  calendar: 'heroicons:calendar',
  check: 'heroicons:check',
  close: 'heroicons:x-mark',
  chat: 'heroicons:chat-bubble-left-ellipsis',
  chatSolid: 'heroicons:chat-bubble-oval-left-ellipsis-solid',
  textMessage: 'heroicons:chat-bubble-left-ellipsis-solid',
  chevronDown: 'heroicons:chevron-down-20-solid',
  chevronLeft: 'heroicons:chevron-left-20-solid',
  chevronRight: 'heroicons:chevron-right-20-solid',
  chevronUp: 'heroicons:chevron-up-20-solid',
  dialpad: 'material-symbols:dialpad', // Deprecated
  download: 'heroicons:arrow-down-tray-20-solid',
  emptyStar: 'mdi:star-outline',
  filledStar: 'mdi:star',
  group: 'heroicons-solid:user-group',
  upload: 'heroicons:arrow-up-tray-20-solid',
  exclamationCircle: 'heroicons-solid:exclamation-circle',
  informationCircle: 'heroicons-outline:information-circle',
  link: 'heroicons:link-20-solid',
  loading: 'prime:spinner',
  minimize: 'heroicons:minus-small-20-solid',
  pencil: 'heroicons:pencil',
  pencilSquare: 'heroicons-solid:pencil-square',
  phone: 'heroicons-solid:phone',
  phoneIncoming: 'heroicons-solid:phone-incoming',
  phoneOutgoing: 'heroicons-solid:phone-outgoing',
  phoneMissed: 'heroicons-solid:phone-x-mark',
  phoneLeftVoiceMail: 'heroicons:phone-arrow-up-right',
  newPhone: 'heroicons:phone',
  video: 'heroicons-solid:video-camera',
  add: 'heroicons:plus-solid',
  print: 'heroicons-outline:printer',
  refresh: 'heroicons-outline:refresh',
  search: 'heroicons:magnifying-glass-20-solid',
  sendMessage: 'heroicons:paper-airplane-20-solid',
  trash: 'heroicons:trash',
  wrench: 'clarity:wrench-line',
  listBullet: 'fluent:text-bullet-list-ltr-20-regular',
  bookOpen: 'heroicons:book-open-20-solid',
  chevronUpDown: 'fluent:chevron-up-down-24-filled',
  counterclockArrow: 'fluent:arrow-counterclockwise-16-filled',
  calendarDays: 'heroicons:calendar-days-20-solid',
  archive: 'heroicons:archive-box-arrow-down',
  blockedStatus: 'heroicons:minus-circle-20-solid',
  cancel: 'heroicons:x-mark-20-solid',
  cancelledStatus: 'heroicons:x-circle-20-solid',
  completedStatus: 'heroicons:check-circle-20-solid',
  declinedTexting: 'tabler:message-circle-x',
  email: 'heroicons:envelope',
  errorNotification: 'heroicons:x-circle',
  infoNotification: 'heroicons:information-circle',
  inProgressStatus: 'tabler:progress',
  insurance: 'heroicons:shield-check',
  leftArrow: 'heroicons:chevron-left',
  meatball: 'heroicons:ellipsis-horizontal',
  notApprovedToSpeak: 'tabler:message-circle-off',
  notConsented: 'tabler:message-circle-question',
  onHoldStatus: 'heroicons:pause-circle-solid',
  openStatus: 'material-symbols:circle-outline',
  padlock: 'heroicons:lock-closed-20-solid',
  pathway: 'heroicons:book-open-solid',
  sensitive: 'heroicons:eye-slash-solid',
  successNotification: 'heroicons:check-20-solid',
  taskName: 'heroicons:clipboard-20-solid',
  unarchive: 'heroicons:arrow-up-on-square',
  reattempt: 'heroicons:arrow-uturn-left-16-solid',
  filterFunnel: 'heroicons:funnel',
  language: 'heroicons:language-20-solid',
  barChart: 'heroicons:chart-bar-square-solid',
  exclamationTriangle: 'heroicons:exclamation-triangle',
  phoneXMark: 'heroicons:phone-x-mark',
  fax: 'heroicons:document-arrow-up',
  documentPlus: 'heroicons:document-plus-20-solid',
  checkCircle: 'heroicons:check-circle-16-solid',
  userCircle: 'teenyicons:user-circle-solid',
  bookOutline: 'heroicons-outline:book-open',
  buildingLibrary: 'heroicons:building-library',
  copy: 'heroicons-outline:square-2-stack',
}

export enum SVGFileIconName {
  EmptyPriority = 'emptyPriority',
  HighPriority = 'highPriority',
  OptionalPriority = 'optionalPriority',
  RoutinePriority = 'routinePriority',
  UrgentPriority = 'urgentPriority',
}
export const AllIcons = { ...SVGFileIconName, ...IconifyIcons }

export const iconPositionProp = { iconPos: createProp(IconPosition) }

export const AllowedIcons = {
  ...AllIcons,
}
export const iconProp = { icon: createProp(AllowedIcons) }
