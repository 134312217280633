<template>
  <div class="grid grid-cols-5">
    <div class="col-span-1 pl-4 pt-4">
      <h2>My Schedule</h2>
      <TFullCalendar
        :events-fn="getDayEvents"
        :aspect-ratio="0.1"
        :event-click="eventClick"
      />
    </div>
    <div class="col-span-4">
      <MyQueueTable ref="myQueue" />
      <TeamQueueTable @assign="refreshMembers" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  EventClickArg,
  EventInput,
  EventSourceFuncArg,
} from '@fullcalendar/core'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import MyQueueTable from '@/legacy/components/queue/MyQueueTable/MyQueueTable.vue'
import TeamQueueTable from '@/legacy/components/queue/TeamQueueTable/TeamQueueTable.vue'
import TFullCalendar from '@/legacy/nashville/fullCalendar/TFullCalendar.vue'
import { useScheduleApi } from '@/legacy/store/modules/page/schedule'
import { useProfileStore } from '@/legacy/store/modules/profile'

export default defineComponent({
  components: { MyQueueTable, TeamQueueTable, TFullCalendar },
  setup() {
    const router = useRouter()
    const myQueue = ref<{ getData: () => object } | null>(null)

    // We need to pass this to the unassigned table because when users assign
    // themselves a certain subtask from unassigned queue, we also need to
    // refresh the my members table along with unassigned table.
    const refreshMembers = () => myQueue.value?.getData()

    const { data: scheduleData } = storeToRefs(useScheduleApi())
    const { selfEntity } = storeToRefs(useProfileStore())

    /**
     * get all events for a staff member on a particular day
     * @param fetchInfo
     */
    async function getDayEvents(fetchInfo: EventSourceFuncArg) {
      await useScheduleApi().listAll({
        params: {
          filter_staff_ids: [selfEntity.value?.entityId],
          filter_time_before: fetchInfo.endStr,
          filter_time_after: fetchInfo.startStr,
        },
      })

      return scheduleData.value as EventInput[]
    }

    /**
     *
     * @param info
     */
    function eventClick(info: EventClickArg) {
      const commId = info.event.id
      const patientId = info.event.extendedProps.patientId
      const routeData = router.resolve({
        name: '#patientProfile',
        params: { patientId },
        query: { commId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      myQueue,
      refreshMembers,
      getDayEvents,
      eventClick,
    }
  },
})
</script>
