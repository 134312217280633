<template>
  <div>
    <AddMedicalConditions
      v-if="creating"
      :patient-id="patientId"
      :exclude-ids="excludeIds"
      data-testid="create-medical-conditions-modal"
      @close="creating = false"
      @refetch="refetch"
    />
    <TModal
      v-else
      :is-visible="true"
      title="Edit Medical Conditions"
      :size="ModalSize.LG"
      :flex="true"
      :allow-overflow="true"
      data-testid="edit-medical-conditions-modal"
      @close="close"
    >
      <div class="h-full overflow-auto">
        <div v-if="!creating" class="flex justify-start mb-4">
          <TMSecondaryButton
            label="+ Add Medical Conditions"
            name="add-medical-conditions"
            @click="creating = true"
          />
        </div>
        <EditMedicalConditions
          :medical-conditions-display="orderedMedicalConditionsDisplay"
          :update-attrs-medical-condition="updateAttrsMedicalCondition"
          :confirm-archive-medical-condition="confirmArchiveMedicalCondition"
          @set-update-attrs-medical-condition="
            updateAttrsMedicalCondition = $event
          "
          @set-archive-medical-condition="
            confirmArchiveMedicalCondition = $event
          "
          @refetch="refetch"
        />
        <TMTertiaryButton
          :label="archivedMedicalConditionsLabelDisplay"
          class="pt-2 my-3"
          icon-pos="right"
          :icon="showArchivedMedicalConditions ? 'chevronUp' : 'chevronDown'"
          @click="fetchArchivedMedicalConditions"
        />
        <EditMedicalConditions
          v-if="showArchivedMedicalConditions"
          :medical-conditions-display="archivedMedicalConditionsDisplay"
          @refetch="refetch"
        />
      </div>
      <template #actions>
        <TMPrimaryButton
          name="save-medical-conditions"
          class="mt-3"
          label="Save"
          @click="close"
        />
      </template>
    </TModal>
    <ArchiveMedicalCondition
      v-if="confirmArchiveMedicalCondition"
      :confirm-archive-medical-condition="confirmArchiveMedicalCondition"
      @close="confirmArchiveMedicalCondition = null"
      @refetch="refetch"
    />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import AddMedicalConditions from './AddMedicalConditions/AddMedicalConditions.vue'
import ArchiveMedicalCondition from './ArchiveMedicalCondition/ArchiveMedicalCondition.vue'
import { setup } from './controller'
import EditMedicalConditions from './EditMedicalConditions/EditMedicalConditions.vue'
import { manageMedicalConditionsProps } from './shared/types'

export default defineComponent({
  components: {
    TMTertiaryButton,
    TMPrimaryButton,
    TMSecondaryButton,
    TModal,
    AddMedicalConditions,
    ArchiveMedicalCondition,
    EditMedicalConditions,
  },
  props: manageMedicalConditionsProps,
  emits: ['close', 'refetch', 'fetchMedicalConditionsWithoutAttrs'],
  setup,
})
</script>
