<template>
  <Transition>
    <TNotification v-if="notification" />
  </Transition>
  <TMenuBar class="print-hidden" :items="items" />
</template>

<script lang="ts">
import { MenuItem } from '@thyme/nashville/src/types/navbar'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import TMenuBar from '@/legacy/nashville/menu/TMenuBar.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'

export default defineComponent({
  components: {
    TMenuBar,
    TNotification,
  },
  setup() {
    const { notification } = storeToRefs(useNotificationStore())
    const { showPracticePageLink, overrideViewAllTasks } = storeToRefs(
      useFlagStore()
    )
    const {
      canViewPagePodLeadQueue,
      canViewEnrollmentQueue,
      canViewPageEnrollmentLeadQueue,
      canViewPathwaysAdmin,
      hasAdminPrivileges,
      canViewPageAllTasks,
      canViewPageAdminCarePods,
      canViewPageUnknownTexts,
      canSendBulkTexts,
      canCreateCohorts,
    } = storeToRefs(useProfileStore())

    /**
     * IMPORTANT NOTES
     * all urls should have a slash at the front to make it an ABSOLUTE url rather than a RELATIVE one
     * trailing slash is required if you intend on making relative url calls from a url (See patients/<id>/report/current for example)
     * items in array are in the order you will see in top bar
     */
    const items = ref<MenuItem[]>([
      {
        label: 'Lead Queue',
        url: '/pod-lead-queue',
        visible: () => canViewPagePodLeadQueue.value,
      },
      {
        label: 'Enrollment Lead Queue',
        url: '/enrollment-lead-queue',
        visible: () => canViewPageEnrollmentLeadQueue.value,
      },
      {
        label: 'My Queue',
        url: '/my-queue',
      },
      {
        label: 'Enrollment Queue',
        url: '/enrollment-queue',
        visible: () => canViewEnrollmentQueue.value,
      },
      {
        label: 'Unknown Texts',
        url: '/unknown-texts',
        visible: () => canViewPageUnknownTexts.value,
      },
      {
        label: 'Members',
        url: '/patients',
      },
      {
        label: 'Tasks',
        url: '/reminders/all-tasks',
        visible: () => canViewPageAllTasks.value || overrideViewAllTasks.value,
      },
      {
        label: 'Practices',
        url: '/practices',
        visible: () => showPracticePageLink.value,
      },
      {
        label: 'Bulk Texting',
        visible: () => canCreateCohorts.value || canSendBulkTexts.value,
        items: [
          {
            label: 'Campaigns',
            url: '/admin/campaigns',
            visible: () => canSendBulkTexts.value,
          },
          {
            label: 'Create Cohort',
            url: '/admin/cohorts',
            visible: () => canCreateCohorts.value,
          },
        ],
      },
      {
        label: 'Admin',
        visible: () =>
          hasAdminPrivileges.value || canViewPageAdminCarePods.value,
        items: [
          {
            label: 'User Mgmt',
            visible: () =>
              hasAdminPrivileges.value || canViewPageAdminCarePods.value,
            items: [
              {
                label: 'Edit Users',
                url: '/admin/users',
                visible: () => hasAdminPrivileges.value,
              },
              {
                label: 'Edit Care Pods',
                url: '/admin/care_pods',
                visible: () =>
                  hasAdminPrivileges.value || canViewPageAdminCarePods.value,
              },
              {
                label: 'Edit Care Pod Assignments',
                url: '/admin/care_pod_assignment',
                visible: () =>
                  hasAdminPrivileges.value || canViewPageAdminCarePods.value,
              },
            ],
          },
          {
            label: 'Edit Care Plan Goals',
            url: '/admin/care_plan_goals',
            visible: () => hasAdminPrivileges.value,
          },
          {
            label: 'Edit Comorbidity References',
            url: '/admin/comorbidity_reference',
            visible: () => hasAdminPrivileges.value,
          },
          {
            label: 'Edit Document Categories',
            url: '/admin/document_category',
            visible: () => hasAdminPrivileges.value,
          },
          {
            label: 'Playbooks',
            visible: () => canViewPathwaysAdmin.value,
            items: [
              {
                label: 'Edit Playbooks',
                url: '/admin/playbooks/playbooks',
              },
              {
                label: 'Edit Tasks',
                url: '/admin/playbooks/tasks',
              },
              {
                label: 'Edit Subtasks',
                url: '/admin/playbooks/subtasks',
              },
              {
                label: 'CSV Uploads',
                url: '/admin/csv',
              },
            ],
          },
          {
            label: 'Outbound Georouting',
            visible: () => hasAdminPrivileges.value,
            items: [
              {
                label: 'Outbound Phone Numbers',
                url: '/admin/thyme_phone_numbers',
              },
              {
                label: 'Outbound Phone Number Configurations',
                url: '/admin/thyme_phone_number_configurations',
              },
            ],
          },
          {
            label: 'Edit Outcomes',
            url: '/admin/outcomes/edit',
            visible: () => hasAdminPrivileges.value,
          },
          {
            label: 'Triggers',
            url: '/admin/triggers',
            visible: () => hasAdminPrivileges.value,
          },
          {
            label: 'Meos Charges',
            url: '/admin/meos_charges',
            visible: () => hasAdminPrivileges.value,
          },
          {
            label: 'Debug Sandbox',
            url: '/sandbox/',
            visible: () => hasAdminPrivileges.value,
          },
        ],
      },
    ])

    return {
      notification,
      items,
    }
  },
})
</script>

<style lang="scss" scoped>
@media print {
  .print-hidden {
    display: none !important;
  }
}
</style>
