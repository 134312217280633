<template>
  <TModal
    :is-visible="true"
    :size="ModalSize.LG"
    :title="modalTitle"
    @close="close"
  >
    <template #preTitle>
      <TIcon class="mr-2 cursor-pointer" icon="chevronLeft" @click="close" />
    </template>
    <div v-for="(field, index) in fields" :key="field.key">
      <div
        v-if="!field.value.removed"
        class="flex flex-row w-full items-center"
      >
        <div class="flex flex-col w-full">
          <div class="flex flex-row w-full align-center space-x-2">
            <div
              v-if="showTreatmentDropdown"
              class="flex flex-col w-full align-center"
            >
              <TMDropdownField
                label="Treatment Type"
                :name="`treatmentPayload[${index}].oncologySystematicTherapyRefId`"
                class="my-2 bg-nash-neutral000"
                filter
                option-label="label"
                option-value="value"
                :options="treatmentOptions"
                :model-value="
                  initialData.oncologySystematicTherapyRefId ?? undefined
                "
                @update:model-value="setTreatmentField($event, index)"
              />
            </div>
            <div
              v-if="
                !showTreatmentDropdown || field.value.otherTreatmentType != null
              "
              class="flex flex-col w-full align-center"
            >
              <TInputText
                id="other-treatment-type-input"
                class="mt-2 mb-3"
                label="Other Treatment"
                :name="`treatmentPayload[${index}].otherTreatmentType`"
                :model-value="initialData.otherTreatmentType"
              />
            </div>
          </div>
          <div class="flex flex-row w-full space-x-2">
            <div class="w-full">
              <TCalendar
                :min-date="null"
                :disable-holidays="false"
                :name="`treatmentPayload[${index}].treatmentStartDate`"
                label="Treatment Start Date"
                class="w-full"
                :model-value="field.value?.treatmentStartDate ?? undefined"
              />
            </div>
            <div class="w-full">
              <TCalendar
                :min-date="null"
                :disable-holidays="false"
                :name="`treatmentPayload[${index}].treatmentEndDate`"
                label="Treatment End Date"
                class="w-full"
                :model-value="field.value?.treatmentEndDate ?? undefined"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <TMSecondaryButton
            v-if="showAdd"
            icon="close"
            class="w-9 h-10 p-9 ml-3 p-4 mt-2"
            @click="removeTreatmentForm(index)"
          />
        </div>
      </div>
    </div>

    <TMTertiaryButton
      v-if="showAdd"
      label="Add Treatment"
      class="pt-2 mb-3"
      icon="add"
      @click="addTreatmentForm"
    />
    <template #actions>
      <TMSecondaryButton
        label="Cancel"
        name="treatment-add-or-edit-cancel"
        @click="close"
      />
      <TMPrimaryButton
        v-if="showAdd"
        name="treatment-add"
        label="Create Treatment(s)"
        @click="onAddSubmit"
      />
      <TMPrimaryButton
        v-if="!showAdd"
        name="treatment-edit"
        label="Save"
        @click="onEditSubmit"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { addOrEditTreatmentProps } from './types'

export default defineComponent({
  components: {
    TIcon,
    TInputText,
    TCalendar,
    TMDropdownField,
    TMTertiaryButton,
    TModal,
    TMSecondaryButton,
    TMPrimaryButton,
  },
  props: addOrEditTreatmentProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
