import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useSymptomIdHistoryApi } from '@/legacy/store/modules/symptomIdHistory'
import { SymptomIdHistoryResponse } from '@/legacy/types/pathways/symptomIdHistory'

/**
 * Setup the SymptomsThymeline component
 */
export default function () {
  // Read patientId from the route
  // IMPROVEME MT-3111 - receive patientId in component props instead of route
  const route = useRoute()
  const patientIdFromRoute = computed(() => route.params.patientId as string)

  const symptomIdHistory = ref<SymptomIdHistoryResponse>()

  // IMPROVEME MT-3110 - show loading spinner while waiting for api response
  // instead of blocking component mount
  onBeforeMount(async () => {
    symptomIdHistory.value = await useSymptomIdHistoryApi().list({
      params: {
        patient_id: patientIdFromRoute.value,
      },
    })
  })

  const symptomIdDisplayNames = {
    symptomIdConstipation: 'Constipation',
    symptomIdDiarrhea: 'Diarrhea',
    symptomIdFatigueTiredness: 'Fatigue/Tiredness',
    symptomIdLackOfAppetite: 'Lack of Appetite',
    symptomIdNausea: 'Nausea',
    symptomIdPain: 'Pain',
    symptomIdShortnessOfBreath: 'Shortness of Breath',
    symptomIdVomiting: 'Vomiting',
    symptomIdDistress: 'Distress',
    symptomIdDrowsinessSleepiness: 'Drowsiness/Sleepiness',
    symptomIdMedications: 'Missing Medications',
  }

  return {
    symptomIdHistory,
    symptomIdDisplayNames,
  }
}
